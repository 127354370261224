<template>
    <div>
        <form>
            <columns>
                <column>
                    <text-input required classes="is-medium is-rounded" :error="$root.errors.name"
                        v-model="area.name">Name</text-input>
                </column>
            </columns>

            <columns>
                <column>
                    <submit-button :working="creating_area" @submit="createArea" class="is-rounded">
                        Create Area
                    </submit-button>
                </column>
            </columns>
        </form>
    </div>
</template>
<script>
import { location as backend } from '@/api'
import { mapGetters } from 'vuex'

export default {

    data: () => ({
        creating_area: false,
        area: {
            name: '',
        }
    }),

    methods: {
        createArea() {
            this.creating_area = true
            backend.createArea({
                uuid: this.location.uuid,
                ...this.area
            }, () => {
                this.$router.back()
            }, () => this.creating_area = false)
        }
    },

    computed: {
        ...mapGetters('location', [
            'location'
        ])
    },

}
</script>